import React from 'react'
import Profile from './views/profile/profile'

import Report from './views/report/report'
import WorkingGroupFinancialReport from './views/report/workinggroupfinancialreport'

import Sample from './views/sample/sample'
import Mountaineering from './views/workingGroups/mountaineering'
import Education from './views/workingGroups/education'
import RockClimbing from './views/workingGroups/rockClimbing'

import Bicycling from './views/workingGroups/bicycling'
import Canyoneering from './views/workingGroups/canyoneering'
import Skyrunning from './views/workingGroups/skyrunning'

import MyJoinPrograms from './views/workingGroups/myjoinprograms'
import MyPrograms from './views/workingGroups/myprograms'
import IceClimbing from './views/workingGroups/iceclimbing'
import Caving from './views/workingGroups/caving'
import Trial from './views/workingGroups/trial'
import ProgramForm from './views/programform/programform'
import Total from './views/members/total'
import Trials from './views/members/trials'
import Guest from './views/members/guest'
import Detail from './views/members/detail'
import Create from './views/members/create'
import TrialDetail from './views/members/trialdetail'
import PageList from './views/site/page/pagelist'
import PageForm from './views/site/page/pageform'

import BillList from './views/financial/billlist'
import ProgramSettings from './views/financial/programsettings'
import ProgramCancelPolicies from './views/financial/programcancelpolicies'

import StoreList from './views/store/storelist'
import StoreForm from './views/store/storeform'
import RentalList from './views/store/rentallist'

import MyBills from './views/financial/mybills'
import MembersWallet from './views/financial/memberswallet'
import MyWallet from './views/financial/mywallet'
import CallbackPayment from './views/financial/callbackpayment'

import MessageList from './views/messages/messagelist'

import CertificateList from './views/certificates/certificatelist'
import CertificateForm from './views/certificates/certificateform'

import Roles from './views/security/roles'
import Role from './views/security/role'
import Permissions from './views/security/permissions'

import DirectorList from './views/site/director/directorlist'
import DirectorForm from './views/site/director/directorform'

import BlogList from './views/site/blog/bloglist'
import BlogForm from './views/site/blog/blogform'

import BannerList from './views/site/banner/bannerlist'
import BannerForm from './views/site/banner/bannerform'
import Skiing from './views/workingGroups/skiing'

//import Dashboard from './views/dashboard/Dashboard'

const Dashboard = React.lazy(() => import('./views/dashboard/Dashboard'))

const Colors = React.lazy(() => import('./views/theme/colors/Colors'))
const Typography = React.lazy(() => import('./views/theme/typography/Typography'))

// Base
const Accordion = React.lazy(() => import('./views/base/accordion/Accordion'))
const Breadcrumbs = React.lazy(() => import('./views/base/breadcrumbs/Breadcrumbs'))
const Cards = React.lazy(() => import('./views/base/cards/Cards'))
const Carousels = React.lazy(() => import('./views/base/carousels/Carousels'))
const Collapses = React.lazy(() => import('./views/base/collapses/Collapses'))
const ListGroups = React.lazy(() => import('./views/base/list-groups/ListGroups'))
const Navs = React.lazy(() => import('./views/base/navs/Navs'))
const Paginations = React.lazy(() => import('./views/base/paginations/Paginations'))
const Placeholders = React.lazy(() => import('./views/base/placeholders/Placeholders'))
const Popovers = React.lazy(() => import('./views/base/popovers/Popovers'))
const Progress = React.lazy(() => import('./views/base/progress/Progress'))
const Spinners = React.lazy(() => import('./views/base/spinners/Spinners'))
const Tables = React.lazy(() => import('./views/base/tables/Tables'))
const Tooltips = React.lazy(() => import('./views/base/tooltips/Tooltips'))

// Buttons
const Buttons = React.lazy(() => import('./views/buttons/buttons/Buttons'))
const ButtonGroups = React.lazy(() => import('./views/buttons/button-groups/ButtonGroups'))
const Dropdowns = React.lazy(() => import('./views/buttons/dropdowns/Dropdowns'))

//Forms
const ChecksRadios = React.lazy(() => import('./views/forms/checks-radios/ChecksRadios'))
const FloatingLabels = React.lazy(() => import('./views/forms/floating-labels/FloatingLabels'))
const FormControl = React.lazy(() => import('./views/forms/form-control/FormControl'))
const InputGroup = React.lazy(() => import('./views/forms/input-group/InputGroup'))
const Layout = React.lazy(() => import('./views/forms/layout/Layout'))
const Range = React.lazy(() => import('./views/forms/range/Range'))
const Select = React.lazy(() => import('./views/forms/select/Select'))
const Validation = React.lazy(() => import('./views/forms/validation/Validation'))

const Charts = React.lazy(() => import('./views/charts/Charts'))

// Icons
const CoreUIIcons = React.lazy(() => import('./views/icons/coreui-icons/CoreUIIcons'))
const Flags = React.lazy(() => import('./views/icons/flags/Flags'))
const Brands = React.lazy(() => import('./views/icons/brands/Brands'))

// Notifications
const Alerts = React.lazy(() => import('./views/notifications/alerts/Alerts'))
const Badges = React.lazy(() => import('./views/notifications/badges/Badges'))
const Modals = React.lazy(() => import('./views/notifications/modals/Modals'))
const Toasts = React.lazy(() => import('./views/notifications/toasts/Toasts'))

const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))

const Widgets = React.lazy(() => import('./views/widgets/Widgets'))

const routes = [
  { path: '/', exact: true, name: 'Home' },
  { path: '/dashboard', name: 'Dashboard', element: Dashboard },
  { path: '/report', name: 'Report', element: Report },
  { path: '/workinggroupfinancialreport', name: 'WorkingGroupFinancialReport', element: WorkingGroupFinancialReport },
  { path: '/theme', name: 'Theme', element: Colors, exact: true },
  { path: '/theme/colors', name: 'Colors', element: Colors },
  { path: '/theme/typography', name: 'Typography', element: Typography },
  { path: '/base', name: 'Base', element: Cards, exact: true },
  { path: '/base/accordion', name: 'Accordion', element: Accordion },
  { path: '/base/breadcrumbs', name: 'Breadcrumbs', element: Breadcrumbs },
  { path: '/base/cards', name: 'Cards', element: Cards },
  { path: '/base/carousels', name: 'Carousel', element: Carousels },
  { path: '/base/collapses', name: 'Collapse', element: Collapses },
  { path: '/base/list-groups', name: 'List Groups', element: ListGroups },
  { path: '/base/navs', name: 'Navs', element: Navs },
  { path: '/base/paginations', name: 'Paginations', element: Paginations },
  { path: '/base/placeholders', name: 'Placeholders', element: Placeholders },
  { path: '/base/popovers', name: 'Popovers', element: Popovers },
  { path: '/base/progress', name: 'Progress', element: Progress },
  { path: '/base/spinners', name: 'Spinners', element: Spinners },
  { path: '/base/tables', name: 'Tables', element: Tables },
  { path: '/base/tooltips', name: 'Tooltips', element: Tooltips },
  { path: '/buttons', name: 'Buttons', element: Buttons, exact: true },
  { path: '/buttons/buttons', name: 'Buttons', element: Buttons },
  { path: '/buttons/dropdowns', name: 'Dropdowns', element: Dropdowns },
  { path: '/buttons/button-groups', name: 'Button Groups', element: ButtonGroups },
  { path: '/charts', name: 'Charts', element: Charts },
  { path: '/forms', name: 'Forms', element: FormControl, exact: true },
  { path: '/forms/form-control', name: 'Form Control', element: FormControl },
  { path: '/forms/select', name: 'Select', element: Select },
  { path: '/forms/checks-radios', name: 'Checks & Radios', element: ChecksRadios },
  { path: '/forms/range', name: 'Range', element: Range },
  { path: '/forms/input-group', name: 'Input Group', element: InputGroup },
  { path: '/forms/floating-labels', name: 'Floating Labels', element: FloatingLabels },
  { path: '/forms/layout', name: 'Layout', element: Layout },
  { path: '/forms/validation', name: 'Validation', element: Validation },
  { path: '/icons', exact: true, name: 'Icons', element: CoreUIIcons },
  { path: '/icons/coreui-icons', name: 'CoreUI Icons', element: CoreUIIcons },
  { path: '/icons/flags', name: 'Flags', element: Flags },
  { path: '/icons/brands', name: 'Brands', element: Brands },
  { path: '/notifications', name: 'Notifications', element: Alerts, exact: true },
  { path: '/notifications/alerts', name: 'Alerts', element: Alerts },
  { path: '/notifications/badges', name: 'Badges', element: Badges },
  { path: '/notifications/modals', name: 'Modals', element: Modals },
  { path: '/notifications/toasts', name: 'Toasts', element: Toasts },
  { path: '/widgets', name: 'Widgets', element: Widgets },
  { path: '*', name: '404', element: Page404 },

  { path: '/profile', name: 'profle', element: Profile },
  { path: '/sample', name: 'sample', element: Sample },
  { path: '/mountaineering', name: 'mountaineering', element: Mountaineering },
  { path: '/education', name: 'mountaineering', element: Education },
  { path: '/rockclimbing', name: 'rockclimbing', element: RockClimbing },
  { path: '/skiing', name: 'skiing', element: Skiing },

  { path: '/skyrunning', name: 'skyrunning', element: Skyrunning },
  { path: '/canyoneering', name: 'canyoneering', element: Canyoneering },
  { path: '/bicycling', name: 'bicycling', element: Bicycling },

  { path: '/iceclimbing', name: 'iceclimbing', element: IceClimbing },
  { path: '/iceclimbing', name: 'iceclimbing', element: IceClimbing },
  { path: '/myjoinprograms', name: 'caving', element: MyJoinPrograms },
  { path: '/myprograms', name: 'myprograms', element: MyPrograms },
  { path: '/caving', name: 'caving', element: Caving },

  { path: '/programform', name: 'programform', element: ProgramForm },
  { path: '/programform/:id', name: 'programform/:id', element: ProgramForm },
  { path: '/trial/:year', name: 'trial/:year', element: Trial },
  { path: '/members/total', name: 'total', element: Total },
  { path: '/members/trials', name: 'members/trials', element: Trials },
  { path: '/members/guest', name: 'members/guest', element: Guest },
  { path: '/members/create', name: 'members/create', element: Create },
  { path: '/members/detail/:id', name: 'members/detail/:id', element: Detail },
  { path: '/members/trialdetail/:id', name: 'members/trialdetail/:id', element: TrialDetail },
  { path: '/site/page/pagelist', name: 'site page list', element: PageList },
  { path: '/site/page/pageform/:id', name: 'site page form', element: PageForm },
  { path: '/site/page/pageform', name: 'site page form', element: PageForm },

  { path: '/site/director/directorlist', name: 'site page list', element: DirectorList },
  { path: '/site/director/directorform/:id', name: 'site director form', element: DirectorForm },
  { path: '/site/director/directorform', name: 'site director form', element: DirectorForm },

  { path: '/site/blog/bloglist', name: 'site blog list', element: BlogList },
  { path: '/site/blog/blogform/:id', name: 'site blog form', element: BlogForm },
  { path: '/site/blog/blogform', name: 'site blog form', element: BlogForm },

  { path: '/financial/billlist', name: 'fiiancial bill list', element: BillList },
  { path: '/financial/programsettings', name: 'fiiancial program setting list', element: ProgramSettings },
  { path: '/financial/programcancelpolicies', name: 'fiiancial program cancelpolicy list', element: ProgramCancelPolicies },
  { path: '/financial/mybills', name: 'fiiancial my bill list', element: MyBills },
  { path: '/financial/memberswallet', name: 'fiiancial members wallet list', element: MembersWallet },
  { path: '/financial/mywallet', name: 'fiiancial member wallet', element: MyWallet },
  { path: '/callbackpayment', name: 'fiiancial callbackpayment', element: CallbackPayment },

  { path: '/messagelist', name: 'message list', element: MessageList },

  { path: '/certificate/certificatelist', name: 'certificate list', element: CertificateList },
  { path: '/certificate/certificateform', name: 'certificate form', element: CertificateForm },
  { path: '/certificate/certificateform/:id', name: 'certificate form', element: CertificateForm },

  { path: '/store/storelist', name: 'store list', element: StoreList },
  { path: '/store/storeform', name: 'store form', element: StoreForm },
  { path: 'store/storeform/:id', name: 'storeform/:id', element: StoreForm },
  { path: 'store/rentallist', name: 'rent from store', element: RentalList },

  { path: '/site/banner/bannerlist', name: 'site banner list', element: BannerList },
  { path: '/site/banner/bannerform/:id', name: 'site banner form', element: BannerForm },
  { path: '/site/banner/bannerform', name: 'site banner form', element: BannerForm },

  { path: '/security/roles', name: 'roles', element: Roles },
  { path: '/security/role/:id', name: 'edit role', element: Role },
  { path: '/security/role', name: 'role', element: Role },
  { path: '/security/permission/:id', name: 'role permissions', element: Permissions },
]

export default routes
